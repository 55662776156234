export default {
  components: {},
  props: {
    shipper: Object,
  },
  data() {
    return {
      country: null,
      frame: null,
      interval: null,
      isLoaded: false,
      opener: null,
      dpdId: null,
    }
  },
  mounted() {
    this.setCountry()

    this.opener = document.createElement('div')
    this.opener.className = 'BranchSelectorDpd__dpdOpener'
    this.opener.style.display = 'none'
    document.body.appendChild(this.opener)

    this.loadDpd()

    let oldValue = this.dpdId
    this.interval = setInterval(() => {
      if (this.dpdId !== oldValue) {
        oldValue = this.dpdId
        this.change(this.dpdId)
      }
    }, 100)
  },
  beforeUnmount() {
    if (this.opener) {
      this.opener.remove()
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    setCountry() {
      const countryObject = this.$store.state.globals.countries.find(
        country => country.alpha3 === this.$store.state.cart.input.country,
      )

      this.country = countryObject ? countryObject.alpha2.toLowerCase() : this.$store.state.globals.zasilkovna.country
    },
    async change() {
      const branch = await this.$store.dispatch('cart/GET_STORE_BRANCH_BY_REMOTE_ID', {
        shipperId: this.shipper.id,
        remoteId: this.dpdId,
      })

      this.$store.commit('cart/SET_BRANCH', branch)
    },
    loadDpd() {
      if (!this.frame) {
        this.frame = document.createElement('iframe')
        this.frame.src = 'https://api.dpd.cz/widget/latest/index.html?disableLockers=true'
        this.frame.addEventListener('load', () => {
          this.isLoaded = true
          this.$emit('init')
          this.opener.style.display = 'block'
        })

        this.opener.appendChild(this.frame)
      }

      window.addEventListener(
        'message',
        event => {
          if (event.data.dpdWidget) {
            if (typeof event.data.dpdWidget?.id === 'string') {
              this.dpdId = event.data.dpdWidget.id
            }

            this.opener.style.display = 'none'
          }
        },
        false,
      )
    },
    open() {
      this.opener.style.display = 'block'
    },
  },
}
